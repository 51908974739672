import { useEffect, useCallback, useState } from "react";
import axios from "axios";
import Logo from '../imgs/newlogoNah.png'
const Navbar = () => {

  const User_TokenType = window.localStorage.getItem("tokenType");
  const User_AccessToken = window.localStorage.getItem("accessToken");
  const [user, setUser] = useState([]);
  const getUserDetails = useCallback(async (accessToken, tokenType) => {

    if(User_AccessToken && User_TokenType != ""){
      accessToken = User_AccessToken
      tokenType = User_TokenType
      handleLogin()
    }

    try {
      const response = await axios.get("https://discord.com/api/users/@me", {
        headers: {
          authorization: `${tokenType} ${accessToken}`
        }
      });
      const { username, avatar, id, discriminator } = response.data;
      document.getElementById("avatar_navbar").src = `https://cdn.discordapp.com/avatars/${id}/${avatar}.jpg`;
      console.log(response.data);
      setUser([username, discriminator]);
      setUser([username]);
      window.localStorage.setItem("tokenType", tokenType)
      window.localStorage.setItem("accessToken", accessToken)
    } catch {

    }
  }, []);

  useEffect(() => {
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    const [accessToken, tokenType] = [
      fragment.get("access_token"),
      fragment.get("token_type")
    ];
    getUserDetails(accessToken, tokenType);
  }, [getUserDetails]);


  const handleLogin = () => {
    const imgElement = document.createElement("img");
    imgElement.className = "img_navbar_pic";
    imgElement.id = "avatar_navbar";


    const PanelA = document.createElement("a");
    const DownloadA = document.createElement("a");
    const HelpA = document.createElement("a");
    const StoreA = document.createElement("a");
    PanelA.textContent = "Panel";
    PanelA.href = "#";
    DownloadA.textContent = "Download";
    DownloadA.href = "#";
    HelpA.textContent = "Help";
    HelpA.href = "#";
    StoreA.textContent = "Store";
    StoreA.href = "#";

    const loginButton = document.getElementById("LoginButton");
    loginButton.parentNode.insertBefore(imgElement, loginButton.nextSibling);
    loginButton.parentNode.insertBefore(HelpA, loginButton.nextSibling);
    loginButton.parentNode.insertBefore(DownloadA, loginButton.nextSibling);
    loginButton.parentNode.insertBefore(PanelA, loginButton.nextSibling);
    loginButton.parentNode.insertBefore(StoreA, loginButton.nextSibling);
    loginButton.remove();
  
    imgElement.addEventListener("click", handleImageClick);
    imgElement.addEventListener("mouseover", handleImageMouseOver);
    imgElement.addEventListener("mouseout", handleImageMouseOut);
  };
  
  
  const handleImageClick = () => {
    const dropdownElement = document.querySelector('.SelectMenu_NavBar_Dopdown');
    const currentDisplay = dropdownElement.style.display;
    dropdownElement.style.transition = 'opacity 0.5s ease';
  
    if (currentDisplay === 'block') {
      dropdownElement.style.opacity = 0;
      setTimeout(() => {
        dropdownElement.style.display = 'none';

    }, 600);
    } else {
      dropdownElement.style.display = 'block';
      setTimeout(() => {
        dropdownElement.style.opacity = 1;
    }, 100)
    }
  };
  

  
  
  const handleImageMouseOver = (event) => {
    event.target.classList.add("img_hovered");
  };
  
  const handleImageMouseOut = (event) => {
    event.target.classList.remove("img_hovered");
  };
  
  
  const SingOut = (event) => {
    window.localStorage.setItem("tokenType", "")
    window.localStorage.setItem("accessToken", "")
    window.location.href = 'http://localhost:3000';
  };

  const Panel = (event) => {
    window.location.href = 'http://localhost:3000/panel';
  };

  const Settings = (event) => {
    window.location.href = 'http://localhost:3000/panel';
  };
  

  return (
    <div className="navbar">
  
      <a href="http://codedevelopment.pl" className="active">  <img src={Logo} alt="Opis obrazka" className="img" /></a>
      <a href="" className="right" id="LoginButton">Soon</a>
      <div className="SelectMenu_NavBar_Dopdown">
        <button className="SelectMenu_NavBar_Dopdown_button" onClick={Panel}>
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#ffffff" d="M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z"/></svg><p> Panel</p>
        </button>
        <button className="SelectMenu_NavBar_Dopdown_button" onClick={Settings}>
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#ffffff" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg><p>Setings</p>
        </button>
        <button className="SelectMenu_NavBar_Dopdown_button" onClick={SingOut}>
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#ffffff" d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"/></svg><p>log off</p>
        </button>
      </div>
    </div>
  );
};
<a href="http://localhost:3000/login" className="right" id="LoginButton">Login</a>
//
export default Navbar;