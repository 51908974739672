
import { BrowserRouter, Router, Route, Switch, Routes } from "react-router-dom";
import Home from './pages/Home';
import Panel from './pages/panel';
import Login from './pages/Login';
import Help from './pages/Help';
import { createRoot } from "react-dom/client";


export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

createRoot(document.getElementById('root')).render(<App />);
//<Route path="/home" element={<Home />} />
//<Route path="/login" element={<Login />} />
//<Route path="/panel" element={<Panel />} />
//<Route path="/help" element={<Help />} />