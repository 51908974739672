//import CookieConsent from "react-cookie-consent";
import Navbar from '../components/navbar';
import NavbarFoot from '../components/footer';
//import FirstTable from '../components/FirstTable';
import HoldUp from '../components/HoldUp';
import '../css/App.css';

import Logo from '../imgs/newlogo.png'
const Home = () => {

  return (
    
    <div className="App">
      <Navbar/>
      <br></br>
      <div className="tempMessage">
        <img alt='imge' src={Logo}></img>
        <p>Hey If u see this then we are actively working on this website. If u wanna support us check out our social below.</p>
        <button>Soon</button>
      </div>
      <br></br>
      <NavbarFoot/>
      <HoldUp/>
      
    </div>
  );
};
//        <div className="ContenerMainPage">
//<br></br>
//<div className="ContenerMainPageFull">
//</div>
//</div>
//<CookieConsent location="bottom"buttonText="Accept"cookieName="myAwesomeCookieConsent"style={{ background: "#2B373B" }}buttonStyle={{ color: "#4e503b", fontSize: "13px" }}expires={150}></CookieConsent>
// less important stuff
//<br></br>
export default Home;