import { useEffect, useCallback, useState } from "react";
import Logo from '../imgs/newlogo.png'
const footer = () => {
  return (
    <div className="footer">
      <div className="footercontainer">
        <div className="footercontainerverticaleft">
        <img src={Logo}></img>
          <a>Small Polish Studio making video games and software.</a>
          <p >Currently v1.0.0.</p>
        </div>
        <div className="footercontainervertical">
           <p >CHECK US!</p>
          <a href="https://github.com/CodeDevelopmentSite">Github</a>
          <a href="https://codedevelopment.itch.io/">Itch.io</a>
          <a href="https://discord.gg/a3QnTNzmgb">Discord</a>
        </div>
        <div className="footercontainervertical">
           <p >EXPLORE</p>
          <a>Panel</a>
          <a>Download</a>
          <a>Home</a>
        </div>
      </div>
      <hr></hr>
      <p className="footer_copyright">Copyright © 2024 All Rights Reserved. CC BY-NC-ND License.</p>
    </div>
  );
};

export default footer;