import React, { useEffect, useState } from "react";
import axios from "axios";
import Logo from '../imgs/newlogo.png'
const HoldUp = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="Holdup">
      {windowWidth <= 770 ? (
        <div className="Holdup" style={{ visibility: "visible" }}>
          <div className="Holdup_Div">
          <img src={Logo}></img>
            <p>Oops</p>
            <a>To small size to show our website</a>
          </div>
        </div>
      ) : (
        <div className="Holdup" style={{ visibility: "hidden" }}>
          <p>Width of the window: {windowWidth}</p>
        </div>
      )}
    </div>
  );
};

export default HoldUp;
